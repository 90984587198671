import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div id="contact" className="contact-section">
    </div>
  );
};

export default Contact;